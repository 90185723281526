.styled-input {
  width: 100%;
  max-width: 100px;
  position: relative;
  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: textfield;
  background: transparent;
  box-shadow: none;
  text-align: center;

  &::placeholder {
    color: #757384;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  & [type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
