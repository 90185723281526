.container-small-2 {
  padding: 0 24px;
  @media (min-width: 768px) {
    max-width: 1000px;
    padding: 0 80px;
  }
  @media (min-width: 1280px) {
    max-width: 866px;
    padding: 0;
  }
}
