.hero-wrapper {
  // arrow animation
  padding-top: 196px;

  .arrows {
    width: 55px;
    height: 72px;
    position: absolute;
    left: 46%;
    bottom: 40%;

    @media (min-width: 768px) {
      bottom: 30%;
    }

    @media (min-width: 1280px) {
      bottom: 20%;
      left: 47%;
    }

    path {
      stroke: white;
      fill: transparent;
      stroke-width: 2px;
      animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;

      &.a1 {
        animation-delay: -1s;
        -webkit-animation-delay: -1s;

        /* Safari 和 Chrome */
      }

      &.a2 {
        animation-delay: -0.5s;
        -webkit-animation-delay: -0.5s;

        /* Safari 和 Chrome */
      }

      &.a3 {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;

        /* Safari 和 Chrome */
      }
    }

    @keyframes arrow {
      0% {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      80% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes arrow

    /*Safari and Chrome*/ {
      0% {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      80% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .hero-box {
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 0 24px;
    height: 100%;

    @media (min-width: 768px) {
      padding: 0 80px;
    }

    @media (min-width: 1280px) {
      max-width: 80%;
      padding: 0;
    }

    .why-img-item {
      background: transparent linear-gradient(166deg, #09033300 0%, #e18c3d 27%, #09033300 73%, #b0641c 100%) 0% 0% no-repeat padding-box;
      padding: 1px;
      margin-top: -30px;
      border-radius: 16px;
      background-size: 300% 300%;
      position: relative;
      animation: gradient 2s ease infinite;

      @media (min-width: 960px) {
        margin-top: -70px;
      }

      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }

      .why-img-item-body {
        background: #333;
        opacity: 0.97;
        padding: 20px;
        display: flex;
        height: auto;
        justify-content: center;
        flex-direction: column;
        border-radius: 16px;
        align-items: center;

        @media (min-width: 960px) {
          flex-direction: row;
          padding: 32px 24px;
          align-items: center;
          height: auto;
        }

        @media (min-width: 1024px) {
          padding: 16px 32px;
        }

        h2 {
          font-weight: 400;
          font-size: 28px;
          line-height: 40px;
          letter-spacing: 1.44px;
          color: #fff;
          text-align: center;
          text-shadow: 0 0 33px #9592a7;
          margin-bottom: 12px;

          @media (min-width: 960px) {
            margin-bottom: 0;
            font-size: 28px;
            margin-right: 15px;
            white-space: nowrap;
          }

          @media (min-width: 1280px) {
            margin-right: 30px;
          }

          @media (min-width: 1600px) {
            margin-right: 37px;
          }
        }
      }
    }
  }

  .hero-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      max-width: 768px;
      margin: auto;
    }

    @media (min-width: 1024px) {
      max-width: 968px;
    }

    @media (min-width: 1280px) {
      max-width: 1104px;
    }

    @media (min-width: 1400px) {
      max-width: 1280px;
    }

    @media (min-width: 1600px) {
      max-width: 1440px;
    }
  }

  .hero-header {
    font-family: Montserrat, Helvetica;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    line-height: 38px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media (min-width: 1400px) {
      font-size: 56px;
      line-height: 64px;
    }
  }

  .hero-text {
    font-family: Montserrat, Helvetica;
    font-size: 16px;
    color: #d0d0d0;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .hero-actions {
    display: flex;
    flex-flow: column wrap;
    margin-top: 32px;

    @media (min-width: 768px) {
      margin-top: 64px;
    }
  }

  .hero-image {
    max-width: 80%;
    margin: 0 auto -32px;

    @media (min-width: 768px) {
      max-width: 50%;
      margin: 0;
    }
  }
}
